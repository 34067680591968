<template>
  <div class="extractDecisionTable">
    <expression-builder-dialog
      :value="expressions(variableToSet)"
      :show-expression-builder="displayExpressionBuilderModal"
      @input="saveExpression($event)(variableToSet)"
      :source="source"
      @cancel="handleCancel"
      @close="handleCancel"
      :use-secure-variables="useSecureVariables"
    />
    <el-table-draggable
      @drag="onDragStart"
      @drop="onDragEnd"
      handle=".action-icon"
      class="draggable"
    >
      <el-table
        class="extract-decision"
        fit
        :data="decisionRules"
        style="width: 100%"
        :row-class-name="generateUniqueRowClass"
      >
        <el-table-column
          prop="decision_condition"
          :label="rule_title || __('Decision Rule')"
        >
          <template slot-scope="scope">
            <img
              alt="icon"
              class="action-icon"
              :src="require('@/assets/icons/icon-drag-handle.svg')"
            />
            <el-row type="flex" class="ruleValue extract-words-column">
              <el-col>
                <el-form-item
                  v-if="!useExpressionBuilder"
                  :error="scope.row.keyword_error"
                >
                  <el-input v-model="scope.row.decision_condition"></el-input>
                </el-form-item>
                <el-form-item v-else :error="scope.row.keyword_error">
                  <expression-input
                    @edit-input="handleVariableSet(scope.row)"
                    v-model="scope.row.decision_condition"
                    :complex-variables="complexVariables"
                    @generated-expression="updateKeyword(scope.row, $event)"
                  ></expression-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="node_name" :label="__('Goto Node')">
          <template slot-scope="scope">
            <el-row
              class="goto-node-configure extract-words-column"
              type="flex"
              justify="start"
            >
              <el-col>
                <el-form-item>
                  <create-or-select
                    :items="extractGotoNodeOptions"
                    :current_select="currentSelection(scope.row)"
                    :fail-to-create-message="scope.row.error"
                    :new-item-message="__('new node')"
                    :placeholder="__('Node Name')"
                    @change="handleChange($event)(scope.row)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column class-name="row-message" width="100px" prop="msg" />
        <el-table-column class-name="cell-item-pointer" width="40px">
          <template slot-scope="scope">
            <span @click="removeWordRule(scope.$index)">
              <i class="el-icon-circle-close"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { getComplexVariables } from "@/api/variables";
import _ from "lodash";
import CreateOrSelect from "../components/CreateOrSelect";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import ElTableDraggable from "element-ui-el-table-draggable";

export default {
  components: {
    ExpressionInput,
    CreateOrSelect,
    ExpressionBuilderDialog,
    ElTableDraggable
  },
  props: {
    value: {
      required: true,
      type: Array
    },
    source: {
      required: false,
      type: String,
      default: ""
    },
    rule_title: {
      required: false,
      type: String
    },
    useExpressionBuilder: {
      required: false,
      type: Boolean,
      default: true
    },
    gotoOptions: {
      required: true,
      type: Array
    },
    useSecureVariables: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    const decision_rule = {
      decision_condition: "",
      node_name: "",
      node_id: -1
    };
    return {
      variableToSet: {},
      decision_rule,
      $decision_rules: [{ ...decision_rule }],
      complexVariables: [],
      $gotoOptions: []
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit,
      clickedNode: state => state.clickedNode,
      showExpressionBuilder: state => state.showExpressionBuilder
    }),
    ...mapGetters("expressionbuilder", {
      parameterCountFor: "parameterCountFor",
      isJsonFunction: "isJsonFunction"
    }),
    extractGotoNodeOptions() {
      const options = _.map(this.gotoOptions, child => ({
        label: child.node_name,
        value: child.node_id
      }));
      return this.$data.$gotoOptions.concat(options);
    },

    decisionRules() {
      return this.$data.$decision_rules;
    },
    displayExpressionBuilderModal: {
      get: function() {
        return this.showExpressionBuilder;
      },
      set: function(val) {
        this.setExpressionBuilderModalShow(val);
      }
    },
    expressions: {
      get: function() {
        return row => {
          return row.decision_condition || "{}";
        };
      }
    },
    currentSelection() {
      return row => {
        const { node_id, node_name } = row;
        return node_id === -1 ? node_name : node_id;
      };
    }
  },
  methods: {
    ...mapActions("canvas", {
      setExpressionBuilderModalShow: "setExpressionBuilderModalShow"
    }),

    updateKeyword(row, val) {
      row.keyword = val;
    },

    generateUniqueRowClass({ rowIndex }) {
      return "row row-" + rowIndex;
    },

    onDragStart() {
      let selector = ".el-table__body-wrapper tbody .row";
      const rows = document.querySelectorAll(selector);

      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        row.classList.add("grabbing");
      }
    },

    onDragEnd() {
      let selector = ".el-table__body-wrapper tbody .row";
      const rows = document.querySelectorAll(selector);

      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        if (row.classList.contains("grabbing")) {
          row.classList.remove("grabbing");
        }
      }
    },

    addNewWordRule() {
      this.$data.$decision_rules.push({ ...this.decision_rule });
    },
    removeWordRule(index) {
      this.$data.$decision_rules.splice(index, 1);
    },
    handleVariableSet(variable) {
      this.variableToSet = variable;
      this.displayExpressionBuilderModal = true;
    },
    saveExpression({ expression, complexVariables }) {
      return row => {
        this.displayExpressionBuilderModal = false;
        row.decision_condition = expression;
        this.complexVariables = complexVariables;
      };
    },
    handleCancel() {
      this.displayExpressionBuilderModal = false;
    },
    initializeKeywordMatches(decisionRules) {
      // console.log("Initial Word rules:", decisionRules);
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(decisionRules) && decisionRules.length) {
          this.$data.$decision_rules = _.cloneDeep(decisionRules);
        }
        // console.log("Initial Word rules 2:", decisionRules);
        if (
          !_.some(
            this.$data.$decision_rules,
            wordRule => !wordRule.decision_condition.length
          )
        ) {
          // console.log("Adding rule")
          this.addNewWordRule();
          this.$emit("input", _.cloneDeep(this.$data.$decision_rules));
        }
      }
    },
    handleChange(option) {
      return row => {
        this.$set(row, "node_id", option.value);
        this.$set(row, "node_name", option.label);
        this.$set(row, "msg", option.msg);
      };
    },
    groupkeywordMatches(decisionRules) {
      return _(_.filter(decisionRules, rule => rule.node_name.length))
        .groupBy("node_name")
        .values()
        .value();
    },
    duplicateVariablesFound(groups) {
      return _.some(
        groups,
        group => !_.some(group, groupItem => groupItem.node_id > -1)
      );
    },
    duplicateGroups(decisionRules) {
      const groups = this.groupkeywordMatches(decisionRules);
      return !this.duplicateVariablesFound(groups)
        ? []
        : _.filter(
            groups,
            group => !_.some(group, groupItem => groupItem.node_id > -1)
          );
    }
  },
  async created() {
    if (_.isEmpty(this.complexVariables) && !_.isEmpty(this.clickedNode)) {
      await getComplexVariables(this.clickedNode.task_id)
        .then(({ data }) => {
          this.complexVariables = data.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeKeywordMatches"
    },
    decisionRules: {
      handler: function(decisionRules) {
        // the below map method is not functional right now as no logic is being
        // tested to remove the variable conflict (ie, different
        // word rule mapped to same variable)

        // console.log("Keyword matches handler:", decisionRules);
        this.$emit("input", _.cloneDeep(decisionRules));
        // this.decisionCondition = this.$data.$decision_rules;
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/expression-builder.scss";

.extractDecisionTable ::v-deep .el-table--medium td {
  padding: 0;
}

.extractDecisionTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.extract-words-column {
  display: flex;
  flex: 1;
}

.extractDecisionTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.extract-decision ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.extractDecisionTable ::v-deep .el-table::before {
  background-color: white !important;
}

.extractDecisionTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.draggable .action-icon {
  cursor: grab;
}

.grabbing {
  cursor: grabbing !important;
}

.grabbing * {
  cursor: grabbing !important;
}
</style>
