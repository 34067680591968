<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
    >
      <el-row type="flex">
        <el-col :span="12">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" style="padding-top: 10px">
        <el-col :span="24">
          <el-form-item
            prop="decision_node.data.keyword_conditions.data"
            :label="__('Match Conditions')"
          >
            <match-decisions
              v-model="decisionRules"
              :gotoOptions="generateGotoOptions"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" style="padding-top: 10px">
        <el-col :span="24">
          <el-form-item
            prop="otherwise"
            class="is-required"
            :label="__('Otherwise Goto Node')"
          >
            <other-wise
              v-model="otherwiseCondition"
              fromNodeType="decision"
              :goto-options="generateGotoOptions"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MatchDecisions from "../components/MatchDecisions";
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import _ from "lodash";
import OtherWise from "../components/OtherWise";
import {
  getSubKeyObject,
  filterRowsIfSomeKeyValueIsAbsent
} from "@/utils/collection";

export default {
  mixins: [BaseNode],
  components: {
    MatchDecisions,
    OtherWise
  },
  data() {
    const validateOtherwiseNode = (rule, value, callback) => {
      const otherwiseNode = _.find(
        this.nodeToBind.decision_node.data.keyword_conditions.data,
        { decision_condition: "Otherwise" }
      );
      if (!otherwiseNode) {
        callback(__("Otherwise node cannot be empty"));
      } else {
        if (otherwiseNode.node_id === -1 && !otherwiseNode.node_name) {
          callback(__("Otherwise node cannot be empty"));
        } else if (otherwiseNode.node_id === -1) {
          !_.map(this.getValidNodes, "node_name").includes(
            otherwiseNode.node_name
          )
            ? callback()
            : callback(__("Node exists"));
        } else {
          callback();
        }
      }
    };
    return {
      rules: {
        otherwise: {
          validator: validateOtherwiseNode,
          trigger: "change"
        }
      },
      activeTab: "decision"
    };
  },
  computed: {
    decisionRules: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? []
          : _.filter(
              keyword_conditions,
              condition => condition.decision_condition !== "Otherwise"
            );
      },
      set: function(val) {
        const keywordMatchesExceptOtherwiseCondition = this.decisionRules;
        if (
          // update only if there is a change in keyword conditions excluding the otherwise node
          !_.isEqual(keywordMatchesExceptOtherwiseCondition, val) ||
          _.isEmpty(keywordMatchesExceptOtherwiseCondition)
        ) {
          const otherwiseCondition = this.otherwiseCondition;
          this.$set(
            this.nodeToBind.decision_node.data.keyword_conditions,
            "data",
            val
          );

          if (!_.isEmpty(otherwiseCondition)) {
            // Initialize or Update the otherwise condition
            // this.otherwiseCondition = otherwiseCondition;
            this.nodeToBind.decision_node.data.keyword_conditions.data.push(
              otherwiseCondition
            );
          }
        }
      }
    },
    allKeywordConditions() {
      return _.isEmpty(this.nodeToBind.decision_node.data.keyword_conditions)
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.decision_node.data.keyword_conditions.data,
              keywordCondition => {
                keywordCondition["error"] = "";
                if (
                  keywordCondition.node_id === -1 &&
                  !this.attemptedToSubmit
                ) {
                  keywordCondition["error"] = !_.map(
                    this.getValidNodes,
                    "node_name"
                  ).includes(keywordCondition.node_name)
                    ? ""
                    : __("Node") +
                      " '" +
                      `${keywordCondition.node_name}` +
                      "' " +
                      __("cannot be used here");
                }
                if (
                  keywordCondition.node_id === -1 &&
                  keywordCondition.node_name.toLowerCase() === "disconnect"
                ) {
                  keywordCondition["error"] =
                    " '" +
                    `${keywordCondition.node_name}` +
                    "' " +
                    __("cannot be used as node name");
                }

                keywordCondition["keyword_error"] = "";
                keywordCondition["keyword"] = _.get(
                  keywordCondition,
                  "keyword",
                  ""
                );
                const subKeys = ["node_id", "node_name", "keyword"];

                keywordCondition["keyword_error"] = _.some(
                  this.nodeToBind.decision_node.data.keyword_conditions.data,
                  condition => {
                    condition["keyword"] = _.get(condition, "keyword", "");
                    return (
                      !_.isEqual(
                        getSubKeyObject(keywordCondition, subKeys),
                        getSubKeyObject(condition, subKeys)
                      ) &&
                      keywordCondition.keyword.toString().trim() ===
                        condition.keyword.toString().trim()
                    );
                  }
                )
                  ? "Rule conflict"
                  : "";

                keywordCondition["msg"] = !keywordCondition.error
                  ? keywordCondition["msg"] || ""
                  : "";

                return keywordCondition;
              }
            )
          };
    },
    otherwiseCondition: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? {}
          : this.findKeywordCondition(
              _.cloneDeep(keyword_conditions),
              "Otherwise"
            );
      },
      set: function(otherwiseCondition) {
        if (
          // append otherwise node to key word conditions only if otherwise node content changes
          !_.isEqual(this.otherwiseCondition, otherwiseCondition)
        ) {
          let { data: keyword_conditions } = this.allKeywordConditions;
          if (!_.isEmpty(keyword_conditions)) {
            let index = _.findIndex(keyword_conditions, function(condition) {
              return (
                condition.decision_condition ==
                otherwiseCondition.decision_condition
              );
            });
            if (index !== -1) {
              keyword_conditions.splice(index, 1);
            }
          } else {
            keyword_conditions = [];
          }
          keyword_conditions.push(otherwiseCondition);
          this.$set(
            this.nodeToBind.decision_node.data.keyword_conditions,
            "data",
            keyword_conditions
          );
        }
      }
    },

    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      const { data: keyword_conditions } = this.allKeywordConditions;

      return (
        _.some(keyword_conditions, condition => {
          return condition.error && condition.error.length;
        }) ||
        _.some(keyword_conditions, condition => {
          return condition.keyword_error && condition.keyword_error.length;
        }) ||
        _.some(this.decisionRules, rule => {
          return rule.error && rule.error.length;
        })
      );
    }
  },
  created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.decision_node)) {
      this.$set(this.nodeToBind, "decision_node", {});
      this.$set(this.nodeToBind.decision_node, "data", {});
      if (_.isEmpty(this.nodeToBind.decision_node.data.keyword_conditions)) {
        this.$set(this.nodeToBind.decision_node.data, "keyword_conditions", {});
      }
      this.$set(this.nodeToBind, "node_type", NODE_TYPES.DECISION.NODE_TYPE);
    }
  },
  methods: {
    ...mapActions("canvas", {
      setExpressionBuilderModalShow: "setExpressionBuilderModalShow"
    }),
    findKeywordCondition(conditions, decision_condition) {
      const condition = _.cloneDeep(_.find(conditions, { decision_condition }));
      return _.isEmpty(condition) ? {} : condition;
    },
    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      const decisionRules = _.map(
        filterRowsIfSomeKeyValueIsAbsent(
          _.map(this.allKeywordConditions.data, decisionCondition => ({
            ...decisionCondition,
            keyword: decisionCondition.keyword.toString().trim(),
            decision_condition: decisionCondition.decision_condition
              .toString()
              .trim(),
            node_name: decisionCondition.node_name.toString().trim()
          })),
          "decision_condition,keyword,node_name"
        ),
        condition => {
          const { node_id, node_name, keyword, decision_condition } = condition;
          return { node_id, node_name, keyword, decision_condition };
        }
      );
      this.$set(
        nodeToCleanUp.decision_node.data.keyword_conditions,
        "data",
        decisionRules
      );

      return nodeToCleanUp;
    },
    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/expression-builder.scss";
@import "~@/styles/node-palette.scss";
@import "~@/styles/node_common.scss";
.el-form-item:last-of-type {
  margin-bottom: 10px;
}
</style>
